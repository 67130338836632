import React from "react";
import {StaticImage} from "gatsby-plugin-image";

const CommentsBanner = () => {
  return (
    <StaticImage
        alt="Comments Banner"
        width={1000}
        layout="constrained"
        src="../images/comments-banner.png"
        style={{ marginBottom: "1rem" }}
      />
  );
};

export default CommentsBanner;
