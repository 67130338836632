import { DiscussionEmbed } from "disqus-react";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { Column, Row } from "hedron";
import unescape from "lodash/unescape";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { ArticleAd } from "../components/ArticleAd";
import ArticleMeta from "../components/ArticleMeta";
import CommentsBanner from "../components/CommentsBanner";
import { ContentColumn } from "../components/ContentColumn";
import ContentParser from "../components/ContentParser";
import Layout from "../components/Layout";
import PageContent from "../components/PageContent";
import SEO from "../components/seo";
import SharingBox from "../components/SharingBox";
import SideBar from "../components/SideBar";
import { getExcerpt } from "../lib/getExcerpt";
import styled from "../lib/styled";
import { IWordpressPost } from "../types/wordpressPost";
const ContentHeader = styled.h1`
  font-family: "Bebas Neue";
`;

interface Props extends PageProps {
  data: {
    wpPost: IWordpressPost;
  };
}

const Article: React.FC<Props> = ({ data, location }) => {
  const article = data.wpPost;

  const featuredImage =
    article?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData;

  const disqusShortname = "jurassic-outpost";
  const disqusConfig = {
    url: `https://jurassicoutpost.com/${article.slug}`,
    identifier: article.databaseId,
    title: article.title,
  };

  const author = {
    name: article.author.node.name,
    url: article.author.node.url,
    customAvatar: article.author.node.customAvatar,
  };

  if (article) {
    return (
      <Layout location={location}>
        <Row>
          <SEO
            title={ReactHtmlParser(article.title)}
            author={article.author.node.name}
            imagePath={getSrc(featuredImage)}
            slug={article.slug}
            description={unescape(article.excerpt.replace(/(<([^>]+)>)/gi, ""))}
          />
          <ContentColumn md={9}>
            {featuredImage && (
              <FeaturedImage>
                <GatsbyImage
                  style={{ height: "100%", maxHeight: "100%" }}
                  imgStyle={{ objectFit: "cover" }}
                  image={featuredImage}
                  alt={ReactHtmlParser(article.title)}
                />
              </FeaturedImage>
            )}{" "}
            <PageContent>
              <>
                <ContentHeader>{ReactHtmlParser(article.title)}</ContentHeader>
                <ArticleMeta
                  author={author}
                  date={article.date}
                  title={article.title}
                  slug={article.slug}
                  id={article.databaseId}
                />
                <ContentParser htmlString={article.content} />
                <CommentsBanner />
                <DiscussionEmbed
                  shortname={disqusShortname}
                  config={disqusConfig}
                />
              </>
            </PageContent>
          </ContentColumn>
          <Column md={3}>
            <SideBar
              Sharing={
                <SharingBox
                  title={article.title}
                  slug={article.slug}
                  excerpt={getExcerpt(article)}
                  categories={article.categories.nodes}
                />
              }
            />
          </Column>
        </Row>
      </Layout>
    );
  } else {
    return null;
  }
};

export default Article;

const FeaturedImage = styled.div`
  max-height: 100%;
  height: 258px;
`;

export const query = graphql`
  query WORDPRESS_POST_QUERY($id: String!) {
    wpPost(id: { eq: $id }) {
      ...getWordpressPostContent
    }
  }
`;
